<script lang="ts" setup>
const { t } = useLocale()
const { url } = useImageStorage()

type HotMenu = {
  label: string
  url?: string
  imageUrl: string
  isLink: boolean
}

const hotMenuList = ref<HotMenu[]>([
  {
    label: t('main.hotMenu.motorcycleReview'),
    url: '/review/motorcycle/list',
    imageUrl: 'motorcycle.svg',
    isLink: true,
  },
  {
    label: t('main.hotMenu.board'),
    url: '/board/free',
    imageUrl: 'board.svg',
    isLink: true,
  },
  {
    label: t('main.hotMenu.market'),
    url: '/market/over125Manual',
    imageUrl: 'market.svg',
    isLink: true,
  },
  {
    label: t('main.hotMenu.meetingManage'),
    url: '/my/meeting',
    imageUrl: 'meeting_manage.svg',
    isLink: true,
  },
  {
    label: t('main.hotMenu.findExpert'),
    imageUrl: 'find_expert.svg',
    isLink: false,
  },
])

const findExpertModalTrigger = ref(false)

const clickHotMenu = (item: HotMenu) => {
  item.isLink
    ? navigateTo(item.url)
    : findExpertModalTrigger.value = true
}
</script>

<template>
  <div class="w-full overflow-x-scroll my-5 px-5">
    <div class="w-fit flex items-center gap-x-2.5">
      <div
        v-for="(item, index) in hotMenuList"
        :key="index"
        class="w-[72px] flex flex-col items-center gap-y-1.5 cursor-pointer"
        @click="clickHotMenu(item)"
      >
        <NuxtImg
          :src="url(true, `/assets/menu/${item.imageUrl}`)"
          class="w-[60px] h-[60px]"
        />
        <p class="text-sm font-semibold text-gray05Light dark:text-gray05Dark">
          {{ item.label }}
        </p>
      </div>
      <DialogMoveFindExpert v-model:find-expert-modal-trigger="findExpertModalTrigger" />
    </div>
  </div>
</template>
