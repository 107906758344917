<script setup lang="ts">
import { format } from 'date-fns'
import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'

const user = useSupabaseUser()

const { t } = useLocale()
const toast = useToast()
const { haversine } = useHaversine()
const { arrayShuffle } = useUi()

const { mainSearchExpertRadius, mainSearchLocationAddress, mainSearchLocationLatitude, mainSearchLocationLongitude } = storeToRefs(useUserDataStore())

const { updateData, upsertData } = useUpdateComposable()
const { fetchData, fetchColumnArrayData, schemaFetchOptionData, schemaFetchOptionExceptionData, schemaFetchRangeData, schemaFetchCountOnlyData } = useFetchComposable()
const { executeUserData } = useLoadUserData()

type ReviewOwner = MotorcycleDatabase['motorcycle']['Views']['viewReviews']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row'] & { avatar_url: string } }

type ExpertOwner = Database['public']['Views']['viewNeighborhoodOwnerProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRepair = Database['public']['Views']['viewNeighborhoodRepairProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertCoaching = Database['public']['Views']['viewNeighborhoodCoachingProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertPickup = Database['public']['Views']['viewNeighborhoodPickupProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type ExpertRescue = Database['public']['Views']['viewNeighborhoodRescueProfiles']['Row']
  & { mainAvatarImage: Database['public']['Tables']['avatarList']['Row'] }
  & { mainLocation: Database['public']['Tables']['locationList']['Row'] }
  & { rank: number }

type HotestReviews = MotorcycleDatabase['motorcycle']['Views']['viewReviews']['Row']
type HotestArticle = Database['public']['Views']['viewHotestBoardArticle']['Row']

useHead({
  title: t('pageTitle.home'),
})

definePageMeta({
  middleware: [
    'main-auth',
    'clear-article-page',
    'clear-search-model',
  ],
})

const loginSlideoverTrigger = ref(false)
const marketingAgreementSlideoverTrigger = ref(false)
const changeSearchRadiusModalTrigger = ref(false)

const computedHotestPending = computed(() => {
  return pendingHotMotorcycleReviewList.value || pendingHotBoardArticleList.value || pendingHotMarketArticleList.value
})

const { data: podiumTop3Data, pending: pendingPodiumTop3Data } = await useAsyncData('podiumTop3Data', async () => {
  const { data: response } = await useFetch('/api/profile/podiumTop3', {
    headers: useRequestHeaders(['cookie']),
    immediate: true,
    transform: (payload: Database['public']['Tables']['profiles']['Row'][]) => {
      return payload.slice(0, 3).map((item, index) => ({
        ...item,
        rank: index + 1,
      }))
    },
  })

  return response.value
    ? response.value as (Database['public']['Tables']['profiles']['Row'] & { rank: number })[]
    : []
})

const transformExpertData = async (locationResponse: Database['public']['Tables']['locationList']['Row'][], viewTableName: string) => {
  type NeighborhoodList =
    Database['public']['Views']['viewNeighborhoodOwnerProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodRepairProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodCoachingProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodPickupProfiles']['Row'] |
    Database['public']['Views']['viewNeighborhoodRescueProfiles']['Row']

  const locationsWithRadius = locationResponse?.filter((location: Database['public']['Tables']['locationList']['Row']) => {
    return haversine(
      mainSearchLocationLatitude.value,
      mainSearchLocationLongitude.value,
      location?.latitude ?? 0,
      location?.longitude ?? 0,
    ) <= mainSearchExpertRadius.value
  })

  const uniqueLocations = locationsWithRadius?.filter((location, index, self) =>
    index === self.findIndex((loc: Database['public']['Tables']['locationList']['Row']) => (
      loc.latitude === location.latitude && loc.longitude === location.longitude
    )),
  )

  const currentLocationProfileIds = uniqueLocations
    ? uniqueLocations.map((location: Database['public']['Tables']['locationList']['Row']) => location.update_user_id).filter(Boolean)
    : []

  const neighborhoodList = await fetchColumnArrayData(viewTableName, '*', 'id', currentLocationProfileIds as string[], 'meeting_count', false) as NeighborhoodList[] | null

  return neighborhoodList?.map((list: NeighborhoodList, index: number) => {
    return {
      ...list,
      rank: index + 1,
    }
  }).slice(0, 3) as ExpertOwner[] | ExpertRepair[] | ExpertCoaching[] | ExpertPickup[] | ExpertRescue[]
}

const { data: mainLargeBannerList } = await useAsyncData('mainLargeBannerList', async () => {
  const { data: response } = await useFetch('/api/ads/main/largeBanner', {
    headers: useRequestHeaders(['cookie']),
    immediate: true,
  })

  return response.value
    ? response.value
    : []
})

const { data: expertOwnerList } = useAsyncData('meetingOwnerExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('motorcycle', 'viewReviews', '*', 'update_user_id', user.value?.id ?? '', 'enable_review_meeting', true, '', '')
    : response = await schemaFetchOptionData('motorcycle', 'viewReviews', '*', 'enable_review_meeting', true, '', '')

  const profileIds = response
    ? response.map((item: ReviewOwner) => item.profiles?.id).filter(Boolean)
    : []

  const profileResponse = await fetchColumnArrayData('locationList', '*', 'update_user_id', profileIds) as Database['public']['Tables']['locationList']['Row'][]

  return transformExpertData(profileResponse, 'viewNeighborhoodOwnerProfiles')
}, {
  server: false,
  lazy: true,
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertRepairList } = useAsyncData('meetingRepairExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'detault')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodRepairProfiles')
}, {
  server: false,
  lazy: true,
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertCoachingList } = useAsyncData('meetingCoachingExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodCoachingProfiles')
}, {
  server: false,
  lazy: true,
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertPickupList } = useAsyncData('meetingPickupExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodPickupProfiles')
}, {
  server: false,
  lazy: true,
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: expertRescueList } = useAsyncData('meetingRescueExpertList', async () => {
  let response = null

  user.value?.id
    ? response = await schemaFetchOptionExceptionData('public', 'viewProfiles', '*', 'id', user.value?.id ?? '', '', '', '', '')
    : response = await fetchData('viewProfiles', '*', 'default')

  const profileResponse: Database['public']['Tables']['locationList']['Row'][] = []

  response.map((item: Database['public']['Views']['viewProfiles']['Row'] & { locationList: Database['public']['Tables']['locationList']['Row'][] }) => {
    if (!item.locationList) {
      return
    }

    item.locationList.map((location: Database['public']['Tables']['locationList']['Row']) => {
      profileResponse.push(location)
    })
  })

  return transformExpertData(profileResponse, 'viewNeighborhoodRescueProfiles')
}, {
  lazy: true,
  watch: [mainSearchExpertRadius, mainSearchLocationLatitude, mainSearchLocationLongitude],
})

const { data: hotMotorcycleReviewList, pending: pendingHotMotorcycleReviewList } = useAsyncData('hotMotorcycleReviewList', async () => {
  const viewReviewCount = await schemaFetchCountOnlyData('motorcycle', 'reviews', 'deleted', false)

  let totalReviewCount = 0

  if (viewReviewCount) {
    totalReviewCount = Math.floor(viewReviewCount * 0.3)
  }

  const response = await schemaFetchRangeData('motorcycle', 'viewReviews', '*', 0, totalReviewCount, 'summary_spark', false)

  return shuffleHotestList(response)
}, {
  lazy: true,
})

const { data: hotBoardArticleList, pending: pendingHotBoardArticleList } = useAsyncData('hotBoardArticleList', async () => {
  const viewBoardArticleCount = await schemaFetchCountOnlyData('public', 'viewHotestBoardArticle', 'deleted', false)

  let totalBoardArticleCount = 0

  if (viewBoardArticleCount) {
    totalBoardArticleCount = Math.floor(viewBoardArticleCount * 0.3)
  }

  const response = await schemaFetchRangeData('public', 'viewHotestBoardArticle', '*', 0, totalBoardArticleCount, 'hotest_point', false)

  return shuffleHotestList(response)
}, {
  lazy: true,
})

const { data: hotMarketArticleList, pending: pendingHotMarketArticleList } = useAsyncData('hotMarketArticleList', async () => {
  const viewMarketArticleCount = await schemaFetchCountOnlyData('public', 'viewHotestMarketArticle', 'deleted', false)

  let totalMarketArticleCount = 0

  if (viewMarketArticleCount) {
    totalMarketArticleCount = Math.floor(viewMarketArticleCount * 0.3)
  }

  const response = await schemaFetchRangeData('public', 'viewHotestMarketArticle', '*', 0, totalMarketArticleCount, 'hotest_point', false)

  return shuffleHotestList(response)
}, {
  lazy: true,
})

const shuffleHotestList = (list: HotestReviews[] | HotestArticle[]) => {
  if (!list || list.length === 0) {
    return []
  }

  if (list.length < 3) {
    return list
  }

  return arrayShuffle(list).slice(0, 3)
}

const selectSearchRadius = (radius: number) => {
  mainSearchExpertRadius.value = radius
  changeSearchRadiusModalTrigger.value = false
}

const moveToHotestReview = (reviewId: string, modelId: string, detailPath: string) => {
  navigateTo({ path: detailPath, query: { modelId, reviewId } })
}

const moveToHotestArticle = (id: string, detailPath: string) => {
  navigateTo(`${detailPath}/${id}`)
}

const moveToProfile = (id: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(`/profile/${id}`)
}

const moveToSearchMeeting = (searchMeetingUrl: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(searchMeetingUrl)
}

const moveToSearchArticle = (searchArticleUrl: string) => {
  navigateTo(searchArticleUrl)
}

const moveToWritePost = (path: string) => {
  !user.value?.id
    ? loginSlideoverTrigger.value = true
    : navigateTo(path)
}

const moveToMainBanner = (adsTypeCode: string, linkUrl: string, articleId: string, articleCategory: string, motorcycleReviewId: string, motorcycleModelId: string) => {
  switch (adsTypeCode) {
    case 'ATC001':
    case 'ATC002':
      navigateTo(linkUrl, { external: true })
      break
    case 'ATC003':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/announce/${articleCategory}/${articleId}`)
      break
    case 'ATC004':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/board/${articleCategory}/${articleId}`)
      break
    case 'ATC005':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(`/market/${articleCategory}/${articleId}`)
      break
    case 'ATC006':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo({ path: '/review/motorcycle/detail', query: { reviewId: motorcycleReviewId, modelId: motorcycleModelId } })
      break
    case 'ATC007':
      break
    case 'ATC008':
      !user.value?.id
        ? loginSlideoverTrigger.value = true
        : navigateTo(linkUrl)
      break
  }
}

const openMarketingAgreementSlideover = async () => {
  if (!user.value?.id) {
    return
  }

  const response = await fetchData('profiles', 'id, agreements_id', 'single-option', 'id', user.value?.id ?? '')

  if (!response.agreements_id) {
    marketingAgreementSlideoverTrigger.value = true
  }
}

const marketingAgreement = async (isAgree: boolean) => {
  const upsertPayload = {
    update_user_id: user.value?.id,
    require: true,
    marketing: isAgree,
  }

  const response: Database['public']['Tables']['agreements']['Row'][] | null = await upsertData('agreements', upsertPayload)

  if (response) {
    await updateData('profiles', { agreements_id: response[0]?.id ?? '' }, user.value?.id ?? '')
    marketingAgreementSlideoverTrigger.value = false
    executeUserData()

    isAgree
      ? toast.add({ title: t('message.marketingAgreement.title'), description: t('message.marketingAgreement.description', { datetime: format(new Date(), 'yy.MM.dd') }), timeout: 3000 })
      : toast.add({ title: t('message.marketingDisagreeAgreement.title'), description: t('message.marketingDisagreeAgreement.description', { datetime: format(new Date(), 'yy.MM.dd') }), timeout: 3000 })
  }
}

onMounted(() => {
  openMarketingAgreementSlideover()
})

// const rating = ref(0)

// watchEffect(() => {
//   console.log(rating.value)
// })
</script>

<template>
  <div class="w-dvw tablet:w-[500px] flex flex-col items-center pt-5 pb-14">
    <!-- <ACustomRate
      v-model="rating"
      allow-half
    /> -->
    <FlickingMainPodiumTop3
      :vertical-data="podiumTop3Data"
      :is-pending="pendingPodiumTop3Data"
      :is-sign-in="!!user?.id"
      @click:podium-user="moveToProfile"
    />
    <FlickingMainLargeBanner
      :main-large-banner-list="mainLargeBannerList"
      :is-pending="computedHotestPending"
      :is-sign-in="!!user?.id"
      @click:main-banner="moveToMainBanner"
    />
    <MainHotMenu v-if="user?.id" />
    <WMDivider
      v-if="!user?.id"
      size="xl"
      :ui="{
        border: {
          base: 'border-white dark:border-gray01Dark',
          size: {
            horizontal: {
              xl: 'border-t-[20px]',
            },
          },
        },
      }"
    />
    <MainNeighborhoodExpert
      :is-sign-in="!!user?.id"
      :main-simple-address="mainSearchLocationAddress"
      :search-radius="mainSearchExpertRadius"
      :expert-owner-list="expertOwnerList"
      :expert-repair-list="expertRepairList"
      :expert-coaching-list="expertCoachingList"
      :expert-pickup-list="expertPickupList"
      :expert-rescue-list="expertRescueList"
      @click:expert-profile="moveToProfile"
      @click:search-meeting="moveToSearchMeeting"
      @click:change-search-radius="changeSearchRadiusModalTrigger = true"
    />
    <WMDivider
      size="xl"
      :ui="{
        border: {
          base: 'border-gray02Light dark:border-gray02Dark',
          size: {
            horizontal: {
              xl: 'border-t-[20px]',
            },
          },
        },
      }"
    />
    <MainHotestList
      :is-sign-in="!!user?.id"
      :is-review-pending="pendingHotMotorcycleReviewList"
      :is-board-pending="pendingHotBoardArticleList"
      :is-market-pending="pendingHotMarketArticleList"
      :hot-motorcycle-review-list="hotMotorcycleReviewList"
      :hot-board-article-list="hotBoardArticleList"
      :hot-market-article-list="hotMarketArticleList"
      @click:hotest-review="moveToHotestReview"
      @click:hotest-article="moveToHotestArticle"
      @click:search-article="moveToSearchArticle"
      @click:write-post="moveToWritePost"
    />
    <AFooter />
    <WMSlideover
      v-model="loginSlideoverTrigger"
      class="login-gradient"
      side="bottom"
      :ui="{
        base: 'justify-end items-center',
        height: 'h-fit',
        padding: 'px-5 py-10',
      }"
    >
      <LoginButton
        v-if="!user?.id"
        class="max-w-[400px]"
      />
    </WMSlideover>
    <WMSlideover
      v-model="marketingAgreementSlideoverTrigger"
      class="login-gradient"
      prevent-close
      side="bottom"
      :ui="{
        base: 'justify-end items-center',
        height: 'h-fit',
        padding: 'pt-5 px-5 pb-14',
      }"
    >
      <div class="w-full flex flex-col gap-y-5">
        <p class="text-lg font-semibold text-gray07Light dark:text-gray07Dark break-keep">
          {{ $t('main.marketing.title') }}
        </p>
        <div class="flex flex-col gap-y-2">
          <p class="text-sm font-semibold text-gray05Light dark:text-gray05Dark break-keep">
            {{ $t('main.marketing.subTitle') }}
          </p>
          <div>
            <p
              v-for="(text, index) in $tm('main.marketing.description')"
              :key="index"
              class="text-sm font-light text-gray04Light dark:text-gray04Dark break-keep"
            >
              {{ $rt(text) }}
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col gap-y-2">
          <AButton
            custom-class="w-full"
            button-block
            button-size="xl"
            button-variant="soft"
            button-color="black"
            :button-text="$t('button.agreeLong')"
            @click="marketingAgreement(true)"
          />
          <AButton
            custom-class="w-full"
            button-block
            button-size="xl"
            button-variant="outline"
            button-color="black"
            :button-text="$t('button.disagreeLing')"
            @click="marketingAgreement(false)"
          />
        </div>
      </div>
    </WMSlideover>
    <DialogChangeRadius
      v-model:change-radius-modal-trigger="changeSearchRadiusModalTrigger"
      @select:radius="selectSearchRadius"
    />
  </div>
</template>
