<script setup lang="ts">
const { t } = useLocale()

const findExpertModalTrigger = defineModel('findExpertModalTrigger', {
  type: Boolean,
  default: false,
})

const selectMeeting = ref('')

const radiusList = ref([
  {
    label: t('pageTitle.meetingOwner'),
    value: 'owner',
  },
  {
    label: t('pageTitle.meetingCoaching'),
    value: 'coaching',
  },
  {
    label: t('pageTitle.meetingRepair'),
    value: 'repair',
  },
  {
    label: t('pageTitle.meetingRescue'),
    value: 'rescue',
  },
  {
    label: t('pageTitle.meetingPickup'),
    value: 'pickup',
  },
])

const moveToFindExpert = () => {
  navigateTo(`/meeting/${selectMeeting.value}`)
}
</script>

<template>
  <DialogConfirm
    v-model:confirm-modal-trigger="findExpertModalTrigger"
    :title="$t('dialog.confirm.moveFindExpert')"
    :confirm-text="$t('button.move')"
    :cancel-text="$t('button.cancel')"
    prevent-close
    @confirm="moveToFindExpert"
    @cancel="() => findExpertModalTrigger = false"
  >
    <WMSelect
      v-model="selectMeeting"
      select-class="w-max-[140px] truncate"
      size="xl"
      :options="radiusList"
      :placeholder="$t('label.selectExpert')"
    />
  </DialogConfirm>
</template>
