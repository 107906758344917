<script setup lang="ts">
import Flicking from '@egjs/vue3-flicking'
import { AutoPlay } from '@egjs/flicking-plugins'
import type { Plugin } from '@egjs/vue3-flicking'
import '@egjs/vue3-flicking/dist/flicking.css'

const colorMode = useColorMode()
const { url } = useImageStorage()

type PodiumTop3 = Database['public']['Tables']['profiles']['Row']
  & { rank: number }

const props = withDefaults(
  defineProps<{
    isPending?: boolean
    isSignIn?: boolean
    isAutoPlay?: boolean
    verticalData?: PodiumTop3[] | null
  }>(),
  {
    isPending: false,
    isSignIn: false,
    isAutoPlay: true,
    verticalData: () => [],
  },
)

defineEmits([
  'click:podium-user',
])

const plugin = computed<Plugin[]>(() => {
  const plugins: Plugin[] = []
  if (props.isAutoPlay) {
    plugins.push(new AutoPlay({
      animationDuration: 300,
      duration: 4000,
      direction: 'NEXT',
      stopOnHover: true,
    }) as unknown as Plugin)
  }

  return plugins
})

const option = {
  inputType: ['touch', 'mouse'],
  circular: true,
  horizontal: false,
  deceleration: 0.004,
  panelsPerView: 1,
  noPanelStyleOverride: true,
  preventClickOnDrag: true,
  align: 'center',
  circularFallback: 'bound',
  resizeOnContentsReady: true,
}
</script>

<template>
  <div class="w-full flex flex-col gap-y-2.5 px-5 pb-2.5">
    <div class="w-full flex items-center justify-between">
      <span class="text-xl font-semibold text-gray07Light dark:text-gray07Dark">
        {{ $t('main.podiumTop3.title') }}
      </span>
      <span class="text-sm text-gray05Light dark:text-gray05Dark">
        {{ $t('main.podiumTop3.recentDay') }}
      </span>
    </div>
    <Flicking
      v-if="!isPending && verticalData"
      class="w-full h-12"
      hide-before-init
      first-panel-size="100%"
      :plugins="plugin"
      :options="option"
    >
      <div
        v-for="item, index in verticalData"
        :key="index"
        class="w-full h-12 py-2 px-3 flex flex-wrap items-center gap-2 rounded-xl bg-gray02Light dark:bg-gray02Dark"
      >
        <span class="mr-1 text-base font-semibold text-gray07Light dark:text-gray07Dark">
          {{ item.rank }}
        </span>
        <ACrown
          :podium-point="item.podium_point ?? 0"
          :is-large-chip="false"
          badge-class="w-10 px-0.5 py-0.5 rounded-full border-2 border-white bg-black"
          crown-class="w-4 h-4"
        />
        <span class="text-sm font-semibold text-gray05Light dark:text-gray05Dark">
          {{ item.nickname }}
        </span>
        <div class="flex-auto" />
        <AButton
          button-variant="soft"
          button-color="black"
          button-confirm-color="white"
          :use-leading="!isSignIn"
          :image-url="url(true, `/assets/icon/${colorMode.value === 'light' ? 'lock_button' : 'lock_button_dark'}.svg`)"
          image-class="w-4 h-4"
          :button-text="isSignIn ? $t('button.viewProfile') : $t('button.profile')"
          @click="() => $emit('click:podium-user', item.id)"
        />
      </div>
    </Flicking>
    <ALoading
      :enable-loading-trigger="isPending || !verticalData"
      loading-class="h-12"
      icon-class="w-10 h-10"
    />
  </div>
</template>
